import React, { useState, useEffect } from "react";
import { SwapWidget } from "@uniswap/widgets";
import "@uniswap/widgets/fonts.css";
import "./index.css";
import uniswap from "./assets/uniswap.png";
import mlg from "./assets/mlgCut.mp4";
import mlgLogo from "./assets/MLGLogo.png";
import dorito from "./assets/dorito.png";
import explosion from "./assets/explosion.png";
import illuminati from "./assets/illuminati.png";
import mlgMusic from "./assets/mlgMusic.mp3";
import mtnDew from "./assets/mtnDew.png";
import faze from "./assets/faze.png";
import shrek from "./assets/shrek.jpeg";
import snoopDog from "./assets/snoopDance.gif";
import hitmarkerGif from "./assets/hitmarkerGif.gif";
import wowGif from "./assets/wowGif.gif";
import fch1 from "./assets/4ch1.jpeg";
import fch2 from "./assets/4ch2.jpeg";
import fch3 from "./assets/4ch3.jpeg";
import xLogo from "./assets/xLogo.png";
import telegramLogo from "./assets/telegramLogo.webp";

const generateProps = () => {
  let top = Math.random() * 100;
  let left = Math.random() * 100;

  // Exclude top 10% and left 10% for Uniswap logo
  if (top < 10 && left < 10) {
    return generateProps(); // Recursively generate new props
  }

  return {
    top: `${top}%`,
    left: `${left}%`,
    size: `${Math.random() * 50 + 40}px`,
    rotate: `${Math.random() * 360}deg`,
    animation: [
      "animate-bounce",
      "animate-pulse",
      "animate-spin",
      "animate-ping",
    ][Math.floor(Math.random() * 4)],
  };
};

const MLG = () => {
  const [isModalOpen, setModalOpen] = useState(true);
  const [isMLG, setMLG] = useState(false);
  const [isMuted, setMuted] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const illuminatiCount = 5; // fewer Illuminati on mobile
  const illuminatiProps1 = Array(illuminatiCount)
    .fill(null)
    .map((_, i) => generateProps());

  const explosionCount = 5;
  const explosionProps = Array(explosionCount)
    .fill(null)
    .map((_, i) => generateProps());

  const fazeCount = 5;
  const fazeProps = Array(fazeCount)
    .fill(null)
    .map((_, i) => generateProps());

  const doritoCount = 5;
  const doritoProps = Array(doritoCount)
    .fill(null)
    .map((_, i) => generateProps());

  const hitmarkerGifCount = 15;
  const hitmarkerGifProps = Array(hitmarkerGifCount)
    .fill(null)
    .map((_, i) => generateProps());

  const mtnDewProps = Array(10)
    .fill(null)
    .map((_, i) => generateProps());

  const handleMLGYes = () => {
    setMLG(true);
    setModalOpen(false);
  };

  const handleMLGNo = () => {
    window.location.href = "https://shopping.mattel.com/";
    setMLG(false);
    setModalOpen(false);
  };

  const toggleMute = () => {
    setMuted(!isMuted);
  };

  return (
    <div
      className="h-full flex flex-col items-center justify-center bg-black overflow-x-hidden"
      style={{ cursor: `url('src/assets/hitmarker.png'), auto` }}
    >
      {isMLG && <audio src={mlgMusic} autoPlay muted={isMuted} />}
      <div className="absolute bg-gradient-to-r from-red-600 via-transparent to-blue-600 inset-0 bg-black opacity-20 animate-pulse z-1"></div>
      {isModalOpen && (
        <>
          <div className="absolute inset-0 bg-black opacity-80 z-40"></div>
          <div className="absolute inset-0 flex items-center justify-center z-50 w-full">
            <div className="bg-gradient-to-r from-red-600 via-white to-blue-600 p-8 rounded-lg shadow-lg flex flex-col items-center ">
              <img className="w-64 mb-4" src={mlgLogo} alt="MLG Logo" />
              <p className="text-black font-bold text-2xl mb-4">Are you MLG?</p>
              <div className="flex space-x-4">
                <button
                  className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                  onClick={handleMLGYes}
                >
                  Yes
                </button>
                <button
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  onClick={handleMLGNo}
                >
                  No
                </button>
              </div>
              <img
                className="w-20 h-20 mt-4 animate-spin"
                src={dorito}
                alt="Dorito"
              />
            </div>
          </div>
        </>
      )}

      {!isModalOpen && (
        <button
          className="absolute top-5 right-5 z-50 mt-10 bg-white p-1 rounded-full"
          onClick={toggleMute}
        >
          {isMuted ? "Unmute" : "Mute"}
        </button>
      )}
      <div className="relative bg-gradient-to-r from-red-600 via-transparent to-blue-600 h-screen w-screen">
        {/* Background with opacity */}
        <div className=" absolute inset-0 h-screen bg-cover bg-center bg-no-repeat opacity-100">
          <video
            src={mlg}
            autoPlay
            loop
            muted
            playsInline
            className="h-screen w-full object-cover"
          />
        </div>
        <a
          href="https://app.uniswap.org/tokens/ethereum/0x377f5b632765fe6060e1484b252585b22f038020"
          className="cursor-pointer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={uniswap}
            alt="Uniswap Logo"
            className="absolute mt-10 top-5 left-5 w-10 h-10  bg-white rounded-full p-1"
          />
        </a>
        <a
          href="https://t.me/+1Kgxhtha2WExMDEx"
          className="cursor-pointer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={telegramLogo}
            alt="Uniswap Logo"
            className="absolute mt-10 top-5 left-5 ml-12 w-10 h-10  bg-white rounded-full p-1" // Adjust size and position as needed
          />
        </a>
        <a
          href="https://twitter.com/mlg_coin"
          className="cursor-pointer"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={xLogo}
            alt="Uniswap Logo"
            className="absolute mt-10 top-5 left-5 ml-24 w-10 h-10  bg-black rounded-full p-1" // Adjust size and position as needed
          />
        </a>
        <div className="sticky top-0 z-50 bg-gradient-to-r from-blue-400 to-red-600 p-2 text-center font-extrabold font-bold ">
          <div className="text-white">Contract address coming soon...</div>
        </div>

        <div className="flex justify-center items-center h-screen w-screen">
          <img
            className="w-2/5 dropshadow-glow transform px-4 py-2 animate-pulse bg-white rounded-xl"
            src={mlgLogo}
            alt="MLG Logo"
          />
        </div>

        {illuminatiProps1.map((props, i) => (
          <div
            key={i}
            className={`absolute ${i > 5 ? "hidden md:block" : ""} ${
              props.animation
            }`}
            style={{
              top: props.top,
              left: props.left,
              width: props.size,
              transform: `rotate(${props.rotate})`,
            }}
          >
            <img src={illuminati} alt="Illuminati" />
          </div>
        ))}

        {explosionProps.map((props, i) => (
          <div
            key={i}
            className={`absolute ${i > 5 ? "hidden md:block" : ""} ${
              props.animation
            }`}
            style={{
              top: props.top,
              left: props.left,
              width: props.size,
              transform: `rotate(${props.rotate})`,
            }}
          >
            <img src={explosion} alt="explosion" />
          </div>
        ))}

        <img
          src={snoopDog}
          alt="Snoop Dogg Dancing"
          className="bottom-0 absolute right-3/4"
        />

        <img
          src={snoopDog}
          alt="Snoop Dogg Dancing"
          className="top-0 absolute left-3/4 transform scale-y-[-1]"
        />

        {fazeProps.map((props, i) => (
          <div
            key={i}
            className={`absolute ${i > 5 ? "hidden md:block" : ""} ${
              props.animation
            }`}
            style={{
              top: props.top,
              left: props.left,
              width: props.size,
              transform: `rotate(${props.rotate})`,
            }}
          >
            <img src={faze} alt="faze" />
          </div>
        ))}

        <div className="absolute top-1/4 left-1/4 transform -translate-x-1/2 -translate-y-1/2 animate-bounce">
          <img className="w-32" src={dorito} alt="Dorito" />
        </div>

        <div className="absolute top-1  right-1/2 transform -translate-x-1/2 -translate-y-1/2 animate-bounce">
          <img className="w-20 rotate-45" src={dorito} alt="Dorito" />
        </div>

        <div className="absolute bottom-0  left-1/2 transform  -translate-y-1/2 animate-bounce">
          <img className="w-60 rotate-90" src={dorito} alt="Dorito" />
        </div>

        <div className="absolute hidden bottom-1/4 md:block right-1 transform  -translate-y-1/2 animate-bounce">
          <img className="w-36 rotate-[23deg]" src={dorito} alt="Dorito" />
        </div>

        <div className="absolute top-10 left-1/4 transform  animate-pulse">
          <img className="w-32" src={explosion} alt="Explosion" />
        </div>

        <div className="absolute top-10 left-1/4 transform  animate-pulse">
          <img className="w-32" src={explosion} alt="Explosion" />
        </div>

        <div className="absolute bottom-20 right-1/4 transform  animate-pulse">
          <img className="w-32 rotate-12" src={explosion} alt="Explosion" />
        </div>

        <div className="absolute hidden top-2/4 md:block left-1 transform  animate-pulse">
          <img className="w-32 rotate-45" src={explosion} alt="Explosion" />
        </div>

        <div className="absolute hidden top-1/4 md:block right-1 transform  animate-pulse">
          <img className="w-32" src={explosion} alt="Explosion" />
        </div>

        <div className="absolute bottom-1/4 right-1/4 transform translate-x-1/2 translate-y-1/2 animate-bounce">
          <img className="w-32" src={illuminati} alt="Illuminati" />
        </div>

        <div className="absolute bottom-1/4 left-1/4 transform translate-x-1/2 translate-y-1/2 animate-bounce">
          <img className="w-20 rotate-45" src={illuminati} alt="Illuminati" />
        </div>

        <div className="absolute top-1/4 right-1/4 transform translate-x-1/2 translate-y-1/2 animate-bounce">
          <img className="w-36 -rotate-45" src={illuminati} alt="Illuminati" />
        </div>

        <div className="absolute bottom-20 left-1/2 transform -translate-x-1/2 text-center px-5">
          <h2 className="text-4xl hover:rotate-12 animate-bounce transition-all duration-500 ease-in-out font-semibold mb-4 text-white">
            👑 MLG 360 NO SCOPE💀
          </h2>
        </div>
      </div>
      <div className="relative h-screen w-full">
        {/* Background with opacity */}
        <div className="absolute inset-0 bg-gradient-to-r from-red-600 via-transparent to-blue-600 bg-cover bg-center bg-no-repeat "></div>

        {/* Description */}
        <p className="text-gray-100 font-bold font-serif text-md md:text-xl leading-relaxed absolute py-4 h-full text-center  sm:px-24 mt-20 z-[1000]">
          Total Supply: 360,000,000
          <br />
          Taxes: 4/4
          <br />
          We are going use taxes to not only burn $MLG, but reward those who
          link their activision account the platform (coming soon)
          <br />
          Holders will be airdropped $MLG on days where you surpass your average
          K.D. Tons moee utility on the way!!! 🚀 <br />
          <br />
          ——————————————- 1. Zero team allocation 😮 <br />
          2. Dev former esports pro
          <br />
          3. Major KOLs cooking 🧑‍🍳 <br />
          4. MLG coming back with microsoft buying activision 💰 <br />
        </p>

        <img
          src={snoopDog}
          alt="Snoop Dogg Dancing"
          className="bottom-0 absolute left-3/4"
        />

        <img
          src={snoopDog}
          alt="Snoop Dogg Dancing"
          className="top-0 absolute right-3/4 transform scale-y-[-1]"
        />

        {doritoProps.map((props, i) => (
          <div
            key={i}
            className={`absolute ${i > 5 ? "hidden md:block" : ""} ${
              props.animation
            }`}
            style={{
              top: props.top,
              left: props.left,
              width: props.size,
              transform: `rotate(${props.rotate})`,
            }}
          >
            <img src={dorito} alt="dorito" />
          </div>
        ))}

        <img src={wowGif} className="absolute bottom-0 w-4/5 px-20" />

        {explosionProps.map((props, i) => (
          <div
            key={i}
            className={`absolute ${i > 5 ? "hidden md:block" : ""} ${
              props.animation
            }`}
            style={{
              top: props.top,
              left: props.left,
              width: props.size,
              transform: `rotate(${props.rotate})`,
            }}
          >
            <img src={explosion} alt="explosion" />
          </div>
        ))}

        {hitmarkerGifProps.map((props, i) => (
          <div
            key={i}
            className={`absolute ${i > 5 ? "hidden md:block" : ""} ${
              props.animation
            }`}
            style={{
              top: props.top,
              left: props.left,
              width: props.size,
              transform: `rotate(${props.rotate})`,
            }}
          >
            <img src={hitmarkerGif} alt="hitmarkerGif" />
          </div>
        ))}
        {/* Bottom Right Logo */}
      </div>
      <div className="bg-gradient-to-r from-red-600 via-transparent to-blue-600 bg-cover bg-center bg-no-repeat w-full flex justify-between items-center">
        <div className="Uniswap z-[1000] md:static ml-10">
          <SwapWidget />
        </div>
        <img
          className="w-1/2 h-full mr-10 md:block hidden animate-bounce"
          src={shrek}
        />
      </div>

      <img src={fch1} />
      <img src={fch2} />
      <img src={fch3} />
    </div>
  );
};

export default MLG;
